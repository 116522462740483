var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      show: _vm.showSidebar,
      fetching: _vm.loading,
      title: _vm.$t("Detalhes da receita"),
      width: "850px",
      "no-close-on-backdrop": false,
    },
    on: { hidden: _vm.hide },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "b-card-actions",
              { attrs: { title: _vm.$t("Dados"), "no-actions": "" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "2" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-receivable_id",
                            type: "label",
                            label: _vm.$t("Id do recebível"),
                          },
                          model: {
                            value: _vm.data.receivable.id,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.receivable, "id", $$v)
                            },
                            expression: "data.receivable.id",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "2" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-id",
                            type: "label",
                            label: _vm.$t("Id da parcela"),
                          },
                          model: {
                            value: _vm.data.id,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "id", $$v)
                            },
                            expression: "data.id",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-store",
                            type: "label",
                            filter: "storeName",
                            label: _vm.$t("Loja"),
                          },
                          model: {
                            value: _vm.data.receivable.store,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.receivable, "store", $$v)
                            },
                            expression: "data.receivable.store",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-document",
                            value: _vm.getDocument,
                            type: "label",
                            label: _vm.$t("Nº documento"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "6" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-description",
                            type: "label",
                            label: _vm.$t("Descrição"),
                          },
                          model: {
                            value: _vm.data.receivable.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.receivable, "description", $$v)
                            },
                            expression: "data.receivable.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "6" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-comment",
                            value: _vm.getComments,
                            type: "label",
                            label: _vm.$t("Comentário"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-statement_date",
                            type: "label",
                            filter: "date",
                            label: _vm.$t("Data da emissão"),
                          },
                          model: {
                            value: _vm.data.receivable.issueDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.receivable, "issueDate", $$v)
                            },
                            expression: "data.receivable.issueDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-due_date",
                            type: "label",
                            filter: "date",
                            label: _vm.$t("Data do vencimento"),
                          },
                          model: {
                            value: _vm.data.dueDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "dueDate", $$v)
                            },
                            expression: "data.dueDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: _vm.hasSale ? "4" : "6" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-checking_account",
                            value: _vm.data.receivable.managementAccount.name,
                            type: "label",
                            label: _vm.$t("Conta gerencial"),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.hasSale
                      ? _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "acc_pay_sidebar_info-sale_id",
                                value: _vm.data.receivable.sale.id,
                                type: "label",
                                label: _vm.$t("Venda"),
                                "router-name": "order-read-only",
                                "router-params": {
                                  id: _vm.data.receivable.sale.id,
                                },
                                "router-title": _vm.$t("Visualizar venda"),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-value",
                            value: _vm.data.value,
                            type: "label",
                            filter: "currency",
                            label: _vm.$t("Valor"),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.data.receivable.originalGrossValue
                      ? _c(
                          "b-col",
                          { attrs: { md: "3" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "acc_pay_sidebar_info-value_original",
                                value: _vm.data.receivable.originalGrossValue,
                                type: "label",
                                filter: "currency",
                                label: _vm.$t("Valor Original"),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-payment_method",
                            value: _vm.data.paymentMethod.name,
                            type: "label",
                            label: _vm.$t("Forma de pagamento"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "6" } },
                      [
                        _vm.data.receivable.customer
                          ? _c("FormulateInput", {
                              attrs: {
                                id: "acc_pay_sidebar_info-customer",
                                value: _vm.data.receivable.customer,
                                type: "label",
                                filter: "personName",
                                label: _vm.$t("Cliente"),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-created_by",
                            value: _vm.getCreatedBy,
                            type: "label",
                            label: _vm.$t("Criado por"),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.getModifiedBy
                      ? _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "acc_pay_sidebar_info-modified_by",
                                value: _vm.getModifiedBy,
                                type: "label",
                                label: _vm.$t("Alterado por"),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.data.receivable.receivableOriginId
                      ? _c("b-col", { attrs: { md: "3" } }, [
                          _c("p", { staticClass: "h6 m-0" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("Id Receita de origem")) + " "
                            ),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass: "m-0 hyperlink cursor-pointer",
                              attrs: {
                                id: "acc_pay_sidebar_info-receivable_origin_id",
                              },
                              on: { click: _vm.onSearchReceivableOrigin },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.data.receivable.receivableOriginId
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.getPaidBy
              ? _c(
                  "b-card-actions",
                  {
                    attrs: {
                      title: _vm.$t("Detalhes da baixa"),
                      "no-actions": "",
                    },
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _vm.hasWriteOffDetails
                          ? _c(
                              "b-col",
                              { attrs: { md: "3" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "acc_pay_sidebar_info-payment_date",
                                    type: "label",
                                    filter: "date",
                                    label: _vm.$t("Data do pagamento"),
                                  },
                                  model: {
                                    value: _vm.data.paymentDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "paymentDate", $$v)
                                    },
                                    expression: "data.paymentDate",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.hasWriteOffDetails
                          ? _c(
                              "b-col",
                              { attrs: { md: "3" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "acc_pay_sidebar_info-checking_account",
                                    value: _vm.data.checkingAccount.name,
                                    type: "label",
                                    label: _vm.$t("Conta corrente"),
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.hasWriteOffDetails
                          ? _c(
                              "b-col",
                              { attrs: { md: "3" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "acc_pay_sidebar_info-paid_value",
                                    value: _vm.data.paidValue,
                                    type: "label",
                                    filter: "currency",
                                    label: _vm.$t("Valor Recebido"),
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.hasWriteOffDetails
                          ? _c(
                              "b-col",
                              { attrs: { md: "3" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "acc_pay_sidebar_info-comission_value",
                                    value: _vm.data.commissionValue,
                                    type: "label",
                                    filter: "currency",
                                    label: _vm.$t("Valor da Taxa"),
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.hasWriteOffDetails
                          ? _c(
                              "b-col",
                              { attrs: { md: "3" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "acc_pay_sidebar_info-financial_write_off_payment_method",
                                    value:
                                      _vm.data.financialWriteOffPaymentMethod
                                        .name,
                                    type: "label",
                                    label: _vm.$t("Forma de pagamento"),
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.getPaidBy
                          ? _c(
                              "b-col",
                              { attrs: { md: "5" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "acc_pay_sidebar_info-paid_by",
                                    value: _vm.getPaidBy,
                                    type: "label",
                                    label: _vm.data.financialWriteOffIsActive
                                      ? _vm.$t("Baixado por")
                                      : _vm.$t("Baixa estornada por"),
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.hasWriteOffDetails && _vm.getPaidBy
                          ? _c(
                              "b-col",
                              { attrs: { md: "4" } },
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "acc_pay_sidebar_info-financialWriteOffMode",
                                    value: _vm.$t(
                                      "FinancialWriteOffMode_" +
                                        _vm.data.financialWriteOffMode
                                    ),
                                    type: "label",
                                    label: _vm.$t("Forma da baixa"),
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "sidebar-footer",
        fn: function () {
          return [
            _c("e-button", {
              attrs: {
                variant: "primary",
                icon: "x",
                text: _vm.$t("Fechar"),
                busy: _vm.saving,
              },
              on: { click: _vm.hide },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }