<template>
  <e-sidebar
    :show="showSidebar"
    :fetching="loading"
    :title="$t('Detalhes da receita')"
    width="850px"
    :no-close-on-backdrop="false"
    @hidden="hide"
  >
    <template #content>
      <b-card-actions
        :title="$t('Dados')"
        no-actions
      >
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="acc_pay_sidebar_info-receivable_id"
              v-model="data.receivable.id"
              type="label"
              :label="$t('Id do recebível')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="acc_pay_sidebar_info-id"
              v-model="data.id"
              type="label"
              :label="$t('Id da parcela')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="acc_pay_sidebar_info-store"
              v-model="data.receivable.store"
              type="label"
              filter="storeName"
              :label="$t('Loja')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="acc_pay_sidebar_info-document"
              :value="getDocument"
              type="label"
              :label="$t('Nº documento')"
            />
          </b-col>

          <b-col md="6">
            <FormulateInput
              id="acc_pay_sidebar_info-description"
              v-model="data.receivable.description"
              type="label"
              :label="$t('Descrição')"
            />
          </b-col>
          <b-col md="6">
            <FormulateInput
              id="acc_pay_sidebar_info-comment"
              :value="getComments"
              type="label"
              :label="$t('Comentário')"
            />
          </b-col>

          <b-col md="3">
            <FormulateInput
              id="acc_pay_sidebar_info-statement_date"
              v-model="data.receivable.issueDate"
              type="label"
              filter="date"
              :label="$t('Data da emissão')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="acc_pay_sidebar_info-due_date"
              v-model="data.dueDate"
              type="label"
              filter="date"
              :label="$t('Data do vencimento')"
            />
          </b-col>
          <b-col :md="hasSale ? '4' : '6'">
            <FormulateInput
              id="acc_pay_sidebar_info-checking_account"
              :value="data.receivable.managementAccount.name"
              type="label"
              :label="$t('Conta gerencial')"
            />
          </b-col>
          <b-col
            v-if="hasSale"
            md="2"
          >
            <FormulateInput
              id="acc_pay_sidebar_info-sale_id"
              :value="data.receivable.sale.id"
              type="label"
              :label="$t('Venda')"
              router-name="order-read-only"
              :router-params="{ id: data.receivable.sale.id }"
              :router-title="$t('Visualizar venda')"
            />
          </b-col>

          <b-col md="3">
            <FormulateInput
              id="acc_pay_sidebar_info-value"
              :value="data.value"
              type="label"
              filter="currency"
              :label="$t('Valor')"
            />
          </b-col>
          <b-col
            v-if="data.receivable.originalGrossValue"
            md="3"
          >
            <FormulateInput
              id="acc_pay_sidebar_info-value_original"
              :value="data.receivable.originalGrossValue"
              type="label"
              filter="currency"
              :label="$t('Valor Original')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="acc_pay_sidebar_info-payment_method"
              :value="data.paymentMethod.name"
              type="label"
              :label="$t('Forma de pagamento')"
            />
          </b-col>
          <b-col md="6">
            <FormulateInput
              v-if="data.receivable.customer"
              id="acc_pay_sidebar_info-customer"
              :value="data.receivable.customer"
              type="label"
              filter="personName"
              :label="$t('Cliente')"
            />
          </b-col>

          <b-col md="4">
            <FormulateInput
              id="acc_pay_sidebar_info-created_by"
              :value="getCreatedBy"
              type="label"
              :label="$t('Criado por')"
            />
          </b-col>
          <b-col
            v-if="getModifiedBy"
            md="4"
          >
            <FormulateInput
              id="acc_pay_sidebar_info-modified_by"
              :value="getModifiedBy"
              type="label"
              :label="$t('Alterado por')"
            />
          </b-col>

          <b-col
            v-if="data.receivable.receivableOriginId"
            md="3"
          >
            <p class="h6 m-0">
              {{ $t('Id Receita de origem') }}
            </p>
            <p
              id="acc_pay_sidebar_info-receivable_origin_id"
              class="m-0 hyperlink cursor-pointer"
              @click="onSearchReceivableOrigin"
            >
              {{ data.receivable.receivableOriginId }}
            </p>
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        v-if="getPaidBy"
        :title="$t('Detalhes da baixa')"
        no-actions
      >
        <b-row>
          <b-col
            v-if="hasWriteOffDetails"
            md="3"
          >
            <FormulateInput
              id="acc_pay_sidebar_info-payment_date"
              v-model="data.paymentDate"
              type="label"
              filter="date"
              :label="$t('Data do pagamento')"
            />
          </b-col>
          <b-col
            v-if="hasWriteOffDetails"
            md="3"
          >
            <FormulateInput
              id="acc_pay_sidebar_info-checking_account"
              :value="data.checkingAccount.name"
              type="label"
              :label="$t('Conta corrente')"
            />
          </b-col>
          <b-col
            v-if="hasWriteOffDetails"
            md="3"
          >
            <FormulateInput
              id="acc_pay_sidebar_info-paid_value"
              :value="data.paidValue"
              type="label"
              filter="currency"
              :label="$t('Valor Recebido')"
            />
          </b-col>
          <b-col
            v-if="hasWriteOffDetails"
            md="3"
          >
            <FormulateInput
              id="acc_pay_sidebar_info-comission_value"
              :value="data.commissionValue"
              type="label"
              filter="currency"
              :label="$t('Valor da Taxa')"
            />
          </b-col>

          <b-col
            v-if="hasWriteOffDetails"
            md="3"
          >
            <FormulateInput
              id="acc_pay_sidebar_info-financial_write_off_payment_method"
              :value="data.financialWriteOffPaymentMethod.name"
              type="label"
              :label="$t('Forma de pagamento')"
            />
          </b-col>

          <b-col
            v-if="getPaidBy"
            md="5"
          >
            <FormulateInput
              id="acc_pay_sidebar_info-paid_by"
              :value="getPaidBy"
              type="label"
              :label="
                data.financialWriteOffIsActive ? $t('Baixado por') : $t('Baixa estornada por')
              "
            />
          </b-col>
          <b-col
            v-if="hasWriteOffDetails && getPaidBy"
            md="4"
          >
            <FormulateInput
              id="acc_pay_sidebar_info-financialWriteOffMode"
              :value="$t(`FinancialWriteOffMode_${data.financialWriteOffMode}`)"
              type="label"
              :label="$t('Forma da baixa')"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </template>

    <template #sidebar-footer>
      <e-button
        variant="primary"
        icon="x"
        :text="$t('Fechar')"
        :busy="saving"
        @click="hide"
      />
      <!-- <e-button
        v-if="showReverse"
        class="ml-1"
        :busy="saving"
        variant="danger"
        icon="x-circle"
        :text="$t('Estornar')"
        @click="localReverse"
      /> -->
    </template>
  </e-sidebar>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { alerts, financialDomain } from '@/mixins'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import ESidebar from '@/views/components/ESidebar.vue'
import EButton from '@/views/components/EButton.vue'

const getInitialSidebarData = () => ({
  id: null,
  store: null,
  user: null,
  description: null,
  value: null,
  paymentDate: null,
  paymentMethod: { name: null },
  receivable: {
    customer: null,
    managementAccount: { name: null },
  },
  financialWriteOffPaymentMethod: { name: null },
  checkingAccount: { name: null },
})

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    ESidebar,
    EButton,
  },

  mixins: [alerts, financialDomain],

  props: {},

  data() {
    return {
      showSidebar: false,
      loading: false,
      saving: false,
      data: getInitialSidebarData(),
    }
  },

  computed: {
    getCreatedBy() {
      if (!this.data.createdDate) return null
      return `${this.data.createdBy} (${this.$options.filters.date(this.data.createdDate)})`
    },
    getModifiedBy() {
      if (!this.data.modifiedDate) return null
      return `${this.data.modifiedBy} (${this.$options.filters.date(this.data.modifiedDate)})`
    },
    getPaidBy() {
      if (!this.data.writtenOffDate) return null
      return `${this.data.writtenOffBy} (${this.$options.filters.date(this.data.writtenOffDate)})`
    },
    getComments() {
      return [this.data.receivable.comments, this.data.comments].filter(Boolean).join(' - ')
    },
    getDocument() {
      if (!this.data.receivable.document) return null
      return `${this.data.receivable.document}${
        this.data.receivable.documentType ? ` (${this.data.receivable.documentType})` : ''
      }`
    },

    hasWriteOffDetails() {
      return this.data.paymentDate != null
    },

    hasSale() {
      return this.data.receivable.sale?.id
    },

    showReverse() {
      return this.$can('Reverse', 'FinancialWriteOff')
    },
  },

  methods: {
    async show(existingData) {
      this.showSidebar = true
      this.loading = true

      try {
        if (existingData) {
          const defaultData = getInitialSidebarData()
          const { receivable } = defaultData
          this.data = {
            ...existingData,
            receivable: {
              ...existingData.receivable,
              managementAccount:
                existingData.receivable.managementAccount || receivable.managementAccount,
              customer: existingData.receivable.customer || receivable.customer,
            },
            checkingAccount: existingData.checkingAccount || defaultData.checkingAccount,
            financialWriteOffPaymentMethod:
              existingData.financialWriteOffPaymentMethod ||
              defaultData.financialWriteOffPaymentMethod,
          }
        } else {
          // TODO ter uma busca por id da parcela?
          // const { data } = await this.$http.get(
          //   `/api/accounting/accounts-payable-installment/${installmentId}`
          // )
          // this.data = data
        }
      } catch (error) {
        this.showError({ error })
        this.hide()
      } finally {
        this.loading = false
      }
    },

    async localReverse() {
      await this.reverseWriteOff()
    },

    async reverseWriteOff() {
      const { accountsPayableInstalment } = this.data
      let writeOffId = null
      let writeOffMessage = ''

      writeOffId = accountsPayableInstalment[0]?.financialWriteOffId
      writeOffMessage = this.$t(
        'A receita será cancelada e o lançamento na conta corrente será excluído.'
      )

      const confirmed = await this.confirm({
        text: writeOffMessage,
      })
      if (!confirmed) return

      try {
        this.saving = true
        await this.$http.patch(`/api/financial/write-offs/${writeOffId}/reverse-payment`)
        this.showSuccess({ message: this.$t('Estornado com sucesso') })
        this.$emit('after-reverse')
        this.hide()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    onSearchReceivableOrigin() {
      this.$emit('search-receivable-origin', this.data.receivable.receivableOriginId)
      this.hide()
    },

    cleanData() {
      this.data = getInitialSidebarData()
    },
    hide() {
      this.cleanData()
      this.showSidebar = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
