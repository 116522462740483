<template>
  <div>
    <e-sidebar
      :title="edit ? $t('Editar Receita') : $t('Nova Receita')"
      :show="showSidebar"
      :fetching="fetching"
      :saving="saving"
      width="500px"
      @save="saveReceivable"
      @hidden="showSidebar = false"
    >
      <template #content>
        <div>
          <FormulateForm
            ref="receivableForm"
            name="receivableForm"
          >
            <e-store-combo
              id="receivable-storeId"
              v-model="localReceivable.storeId"
              :required="true"
              only-active-options
              :is-read-only="isFromPartialPayment"
            />
            <e-management-account
              id="receivable-management-account"
              v-model="localReceivable.managementAccountId"
              name="receivableManagementAccount"
              :required="!isFromPartialPayment"
              type="Input"
              :is-read-only="isFromPartialPayment"
            />
            <e-person-search
              id="receivable-customerId"
              v-model="localReceivable.customerId"
              :required="false"
              :label="$t('Cliente')"
              :is-customer="true"
              :is-read-only="isFromPartialPayment"
              :default-options="localReceivable.customer ? [localReceivable.customer] : []"
            />
            <FormulateInput
              id="receivable-value"
              v-model="localReceivable.value"
              :label="$t('Valor')"
              :type="isFromPartialPayment ? 'label' : 'text-number'"
              filter="currency"
              currency="R$"
              :precision="2"
              class="required"
              validation="^required|min:0.01"
            />
            <FormulateInput
              id="receivable-issue-date"
              v-model="localReceivable.issueDate"
              :label="$t('Data da Emissão')"
              :type="isFromPartialPayment ? 'label' : 'datepicker'"
              filter="date"
              :placeholder="$t('dd/mm/aaaa')"
              dropup
              class="required"
              validation="required"
            />
            <FormulateInput
              id="receivable-due-date"
              v-model="localReceivable.dueDate"
              :label="$t('Data de Vencimento')"
              type="datepicker"
              :placeholder="$t('dd/mm/aaaa')"
              class="required"
              validation="required"
            />
            <e-payment-method
              id="receivable-payment-method"
              v-model="localReceivable.paymentMethodId"
              :required="!isFromPartialPayment"
              :is-read-only="isFromPartialPayment"
            />
            <FormulateInput
              id="receivable-document-type"
              v-model="localReceivable.documentType"
              :type="isFromPartialPayment ? 'label' : 'vue-select'"
              :label="$t('Tipo de documento')"
              :class="{ required: !isFromPartialPayment }"
              :validation="isFromPartialPayment ? '' : 'required'"
              :placeholder="$t('Selecione')"
              :options="documentTypeOptions()"
            />
            <e-only-number-input
              id="receivable-document"
              v-model="localReceivable.document"
              :label="$t('Documento')"
              :placeholder="$t('Documento')"
              :required="!isFromPartialPayment"
              :is-read-only="isFromPartialPayment"
            />
            <FormulateInput
              id="receivable-description"
              v-model="localReceivable.description"
              :label="$t('Descrição')"
              :placeholder="$t('Descrição')"
              class="required"
              validation="required"
              :type="isFromPartialPayment ? 'label' : 'text'"
            />
            <FormulateInput
              id="receivable-comments"
              v-model="localReceivable.comments"
              :label="$t('Comentário')"
              :type="isFromPartialPayment ? 'label' : 'textarea'"
              rows="2"
            />
          </FormulateForm>
        </div>
      </template>
    </e-sidebar>
  </div>
</template>

<script>
import {
  ESidebar,
  EStoreCombo,
  EPersonSearch,
  EManagementAccount,
  EPaymentMethod,
} from '@/views/components'
import { financialDomain, uploader } from '@/mixins'
import moment from 'moment'
import EOnlyNumberInput from '@/views/components/inputs/EOnlyNumberInput.vue'

const getInitialReceivable = () => ({
  id: null,
  installmentId: null,
  storeId: null,
  managementAccountId: null,
  paymentMethodId: null,
  customerId: null,
  documentType: null,
  document: null,
  issueDate: moment(),
  dueDate: moment(),
  description: null,
  comments: null,
})

export default {
  components: {
    ESidebar,
    EStoreCombo,
    EManagementAccount,
    EPersonSearch,
    EPaymentMethod,
    EOnlyNumberInput,
  },

  mixins: [uploader, financialDomain],

  props: {
    receivableId: {
      type: Number,
      default: null,
    },
    receivable: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showSidebar: false,
      fetching: false,
      edit: false,
      saving: false,
      localReceivableId: null,
      localReceivable: getInitialReceivable(),
      imageLocal: [],
    }
  },

  computed: {
    isFromPartialPayment() {
      return !!this.localReceivable?.receivableOriginId
    },
  },

  watch: {
    receivable(val) {
      this.localReceivable = val
      // this.imageLocal = val?.image ? [this.getFileInstance(val.image)] : []
    },
    receivableId(val) {
      this.localReceivableId = val
    },
    showSidebar(val) {
      if (!val) {
        this.localReceivable = getInitialReceivable()
        this.localReceivableId = null
        this.$formulate.reset('receivableForm')
      }
    },
    async localReceivableId(val) {
      if (val) {
        try {
          this.fetching = true
          const { data } = await this.$http.get(
            `/api/accounting/receivables/${this.localReceivableId}`
          )
          this.localReceivable = {
            ...data,
            storeId: data.store?.id?.toString(),
            paymentMethodId: data.paymentMethod?.id?.toString(),
            saleId: data.sale?.id?.toString(),
            customerId: data.customer?.id?.toString(),
            managementAccountId: data.managementAccount?.id?.toString(),
          }
          // this.imageLocal = localReceivable?.image
          //   ? [this.getFileInstance(localReceivable.image)]
          //   : []
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      } else {
        this.localReceivable = getInitialReceivable()
      }
    },
  },

  methods: {
    showCreate() {
      this.localReceivable = getInitialReceivable()
      this.imageLocal = []
      this.edit = false
      this.showSidebar = true
    },

    async show({ id, item } = {}) {
      if (!id && !item?.id) {
        this.showCreate()
      } else {
        if (id) this.localReceivableId = id
        if (item) this.localReceivable = item
        this.edit = true
        this.showSidebar = true
      }
    },

    async saveReceivable() {
      this.$refs.receivableForm.showErrors()
      if (this.$refs.receivableForm.isValid) {
        try {
          this.saving = true

          const url = this.isFromPartialPayment
            ? '/api/accounting/receivables/partial'
            : '/api/accounting/receivables'

          const result = await this.$http({
            url,
            method: this.edit ? 'PUT' : 'POST',
            data: this.localReceivable,
          })

          this.showSuccess({
            message: this.$t(`${this.edit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
          })
          this.$emit('save', result.data)
          this.showSidebar = false
        } catch (error) {
          this.showError({ error })
        } finally {
          this.saving = false
        }
      } else {
        this.showInvalidDataMessage()
      }
    },
  },
}
</script>

<style></style>
