<template>
  <section class="pb-2">
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="4">
            <e-store-combo
              v-model="filters.storeId"
              :required="false"
              :placeholder="$t('Todas')"
            />
          </b-col>
          <b-col md="4">
            <e-person-search
              id="client"
              v-model="filters.customerId"
              :label="$t('Cliente')"
              is-customer
              :only-active="false"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="description"
              v-model="filters.description"
              :label="$t('Descrição')"
              :placeholder="$t('Descrição')"
            />
          </b-col>

          <b-col md="4">
            <FormulateInput
              id="nsu"
              v-model="filters.nsu"
              :label="$t('NSU')"
              :placeholder="$t('NSU')"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="sale-id"
              v-model="filters.saleId"
              :label="$t('Id da venda')"
              type="text-number"
              :precision="0"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="receivableOriginId"
              v-model="filters.receivableOriginId"
              :label="$t('Id Receita de origem')"
              type="text-number"
              :precision="0"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="value"
              v-model="filters.value"
              :label="$t('Valor')"
              type="text-number"
              currency="R$"
              :precision="2"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="status"
              v-model="filters.paymentStatus"
              type="vue-select"
              :label="$t('Status')"
              :placeholder="$t('Todos')"
              :options="statusTypes"
            />
          </b-col>
          <b-col md="4">
            <e-payment-method
              id="payment_method"
              v-model="filters.paymentMethods"
              multiple
              :placeholder="$t('Todos')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="expense-period_type"
              v-model="filters.periodType"
              type="vue-select"
              :label="$t('Tipo do período')"
              :options="receivablePeriodTypeOptions()"
              :clearable="false"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="expense-period"
              v-model="filters.period"
              type="date-range-picker"
              :label="$t('Período')"
              :time-picker="false"
              opens="left"
              class="required"
              validation="requiredrange"
              :validation-messages="{
                requiredrange: $t('Informe um período'),
              }"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-row
      v-show="false"
      class="mb-2"
    >
      <b-col md="3">
        <e-total-summary-card
          :title="$t('Pendente')"
          title-class="text-warning"
          :value="summaryData.pending.value"
          has-predicted-value
          :predicted-value="summaryData.pending.predicted"
        >
          <p class="mb-0">
            Receb. abertos: <br>
            {{ summaryData.pending.secondValue }}
          </p>
        </e-total-summary-card>
      </b-col>
      <b-col md="3">
        <e-total-summary-card
          :title="$t('Em atraso')"
          title-class="text-danger"
          :value="summaryData.overdue.value"
          has-predicted-value
          :predicted-value="summaryData.overdue.predicted"
        >
          <p class="mb-0">
            Receb. em atraso: <br>
            {{ summaryData.overdue.secondValue }}
          </p>
        </e-total-summary-card>
      </b-col>
      <b-col md="3">
        <e-total-summary-card
          :title="$t('Quitado')"
          title-class="text-primary"
          :value="summaryData.paidOff.value"
        >
          <p class="mb-0">
            Total pago: <br>
            {{ summaryData.paidOff.secondValue | currency }}
          </p>
        </e-total-summary-card>
      </b-col>
      <b-col md="3">
        <e-total-summary-card
          :title="$t('Total')"
          title-class="text-info"
          :value="summaryData.total.value"
          has-predicted-value
          :predicted-value="summaryData.total.predicted"
        >
          <p class="mb-0">
            Total pago: <br>
            {{ summaryData.total.secondValue | currency }}
          </p>
        </e-total-summary-card>
      </b-col>
    </b-row>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="bills-table"
        show-empty
        responsive
        striped
        small
        class="bordered receivable-select-row"
        :tbody-tr-class="rowClass"
        :fields="fields"
        :items="receivables"
        :empty-text="getEmptyTableMessage($tc('RECEIVABLE.NAME'), 'female')"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @row-clicked="onRowClickToSelectPay"
        @sort-changed="getData"
      >
        <template #cell(action)="row">
          <e-grid-actions
            :show-update="canUpdate(row.item)"
            :show-delete="!allActionsBlocked(row.item) && canDelete(row)"
            :show-read-only="true"
            :buttons="gridActions(row)"
            @read-only="onShowDetails(row)"
            @delete="onDeleteInstallment(row.item)"
            @update="showUpdateSidebar(row)"
            @receive-account="showReceiveModal(row)"
            @reverse-payment="localReversePayment(row.item)"
          />
        </template>

        <template #head(bulkPay)>
          <p class="ml-1">
            <e-instructions
              :title="$t('Baixa em massa')"
              :instruction="
                $t(
                  'Selecione os registros para baixar e depois vá no botão inferior direto da tela para ver as opções de recebimento em massa.'
                )
              "
            />
            <FormulateInput
              id="input-check_all"
              v-model="allCheckedToReceive"
              type="e-checkbox"
              class="mt-1"
              :title="$t('Marca ou desmarca todos os itens desta página.')"
              @change="(val) => onSelectAllToPay(val)"
            />
          </p>
        </template>
        <template #cell(bulkPay)="row">
          <template v-if="row.item.paymentDate">
            <p class="h4">
              <b-icon
                icon="check-circle-fill"
                variant="primary"
                :title="$t('Recebido')"
              />
            </p>
          </template>
          <div
            v-else
            class="check-td"
          >
            <FormulateInput
              v-model="row.item.checkedToReceive"
              type="e-checkbox"
              @change="(val) => onSelectToPay(val, row.item)"
            />
          </div>
        </template>

        <!-- <template #cell(info)="row">
          <b>{{ $t('Id da Parcela') }}:</b> {{ row.item.id }}<br>
          <b>{{ $t('Loja') }}:</b> {{ row.item.receivable.store | storeName }}<br>
          <div v-if="row.item.receivable.sale && row.item.receivable.sale.id">
            <b>{{ $t('Venda') }}:</b>&nbsp;
            <router-link
              :to="{ name: 'order-read-only', params: { id: row.item.receivable.sale.id } }"
              class="hyperlink"
            >
              <feather-icon icon="ExternalLinkIcon" />
              {{ row.item.receivable.sale.id }}
            </router-link>
          </div>
          <div v-if="row.item.paymentMethod">
            <b>{{ $t('Forma Pagto.') }}:</b>
            {{ row.item.paymentMethod.name }}
          </div>
          <div
            v-if="
              row.item.receivable.issueDate &&
                date(row.item.receivable.issueDate) !== date(row.item.receivable.createdDate)
            "
          >
            <b>{{ $t('Emis.') }}:</b>&nbsp;
            {{ row.item.receivable.issueDate | date('-') }}
          </div>
          <b>{{ $t('Lanç.') }}:</b> {{ row.item.receivable.createdDate | date }}<br>
          <b>{{ $t('Venc.') }}:</b> {{ row.item.dueDate | date('-') }}<br>
          <b>{{ $t('Pago') }}:</b> {{ row.item.paymentDate | date('-') }}
        </template> -->

        <!-- <template #cell(aditionalInfo)="row">
          <div v-if="row.item.receivable.managementAccount">
            <b>{{ $t('Conta Gerencial') }}:</b> {{ row.item.receivable.managementAccount.name
            }}<br>
          </div>
          <div v-if="row.item.receivable.customer">
            <b>{{ $t('Cliente') }}:</b> {{ row.item.receivable.customer.name }}
          </div>
          <b>{{ $t('Desc.') }}:</b> {{ row.item.receivable.description || '-' }}<br>
          <div v-if="row.item.createdBy">
            <b>{{ $t('Criado por') }}:</b> {{ row.item.createdBy || '-' }} ({{
              row.item.createdDate | datetime
            }})<br>
          </div>
          <div v-if="showModifiedBy(row.item)">
            <b>{{ $t('Alterado por') }}:</b>
            {{ row.item.modifiedBy || '-' }} ({{ row.item.modifiedDate | datetime }})<br>
          </div>
          <div v-if="row.item.writtenOffBy">
            <b>{{
              row.item.financialWriteOffIsActive ? $t('Baixado por') : $t('Baixa estornada por')
            }}:</b>
            {{ row.item.writtenOffBy || '-' }} ({{ row.item.writtenOffDate | datetime }})<br>
          </div>
          <div v-if="row.item.checkingAccount">
            <b>{{ $t('Conta corrente') }}:</b>
            <span> {{ row.item.checkingAccount ? row.item.checkingAccount.name : '' }} </span>
          </div>
        </template> -->

        <template #cell(store)="row">
          {{ row.item.receivable.store | storeId }}<br> </template>

        <template #cell(customer)="row">
          <div v-if="row.item.receivable.customer">
            {{ row.item.receivable.customer.name || row.item.receivable.customer.companyName }}
          </div>
        </template>

        <template #cell(issueDate)="row">
          <span v-if="row.item.receivable.issueDate">
            {{ row.item.receivable.issueDate | date }}
          </span>
          <span v-else> - </span>
        </template>

        <template #cell(dueDate)="row">
          <span v-if="row.item.dueDate"> {{ row.item.dueDate | date }} </span>
          <span v-else> - </span>
        </template>

        <template #cell(description)="row">
          {{ row.item.receivable.description || '-' }}
        </template>

        <template #cell(paymentMethod)="row">
          <span :title="row.item.paymentMethod.name">
            {{ row.item.paymentMethod.name || '-' | truncate(20) }}
          </span>
        </template>

        <!-- <template #cell(document)="row">
          <div v-if="row.item.receivable.document">
            {{
              `${row.item.receivable.document}${
                row.item.receivable.documentType ? ` (${row.item.receivable.documentType})` : ''
              }`
            }}
          </div>
        </template> -->

        <template #custom-foot>
          <tr>
            <th
              colspan="7"
              class="text-right"
            >
              Total
            </th>
            <th class="text-right">
              {{ totalValue | currency }}
            </th>
            <!-- <th class="text-right">
              {{ totalCommissionValue | currency }}
            </th> -->
            <th class="text-right">
              {{ totalPaidValue | currency }}
            </th>
            <th colspan="2" />
          </tr>
          <tr v-if="hasBulkPayment">
            <th
              colspan="7"
              class="text-right"
            >
              Total Selecionado
            </th>
            <th class="text-right">
              {{ getTotalInstallmentsSelected | currency }}
            </th>
            <th colspan="2" />
          </tr>
        </template>
      </b-table>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="accounts-payable-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      v-if="!hasBulkPayment && $can('Create', 'Receivable')"
      :main-tooltip="$t('Adicionar Recebimento')"
      @click="showCreateSidebar"
    />

    <receive-installment-modal
      ref="receiveInstallmentModal"
      @after-confirm="({ itemList }) => onAfterPay(itemList)"
      @remove-installment="
        (item) => {
          removeInstallmentsToPay(item)
          getData()
        }
      "
    />

    <receivable-sidebar
      ref="receivable_sidebar"
      @save="getData"
    />

    <receivable-installment-info-sidebar
      ref="receivableInstallmentInfoSidebar"
      @search-receivable-origin="onSearchReceivableOrigin"
    />

    <fab
      :main-tooltip="$t('Ações')"
      main-icon="keyboard_command_key"
      :fixed-tooltip="true"
      :actions="fabActions"
      @clean-bulk-receivement="cleanBulkPayemnt"
      @confirm-bulk-receivement="showBulkPayModal"
      @add-new="showCreateSidebar"
      @add-import="createBulkBill"
      @click="() => { }"
    />
  </section>
</template>

<script>
import moment from 'moment'
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  ETotalSummaryCard,
  EFilters,
  EGridActions,
  EStoreCombo,
  EPersonSearch,
} from '@/views/components'
import Fab from '@/views/components/FAB.vue'
import { mapActions, mapState } from 'vuex'
import { financialDomain, formulateValidation } from '@/mixins'
import EInstructions from '@/views/components/EInstructions.vue'
import EPaymentMethod from '@/views/components/inputs/EPaymentMethod.vue'
import ReceivableSidebar from '../components/ReceivableSidebar.vue'
import ReceiveInstallmentModal from './components/ReceiveInstallmentModal.vue'
import ReceivableInstallmentInfoSidebar from './components/ReceivableInstallmentInfoSidebar.vue'

export default {
  components: {
    BCardActions,
    EPageSizeSelector,
    EPaginationSummary,
    Fab,
    BRow,
    BCol,
    BPagination,
    BTable,
    ETotalSummaryCard,
    EFilters,
    EGridActions,
    EStoreCombo,
    ReceivableSidebar,
    EInstructions,
    ReceiveInstallmentModal,
    EPaymentMethod,
    EPersonSearch,
    ReceivableInstallmentInfoSidebar,
  },

  mixins: [formulateValidation, financialDomain],

  data() {
    return {
      fetching: false,
      allCheckedToReceive: false,
      summaryData: {
        pending: {
          value: 0,
          predicted: 0,
          secondValue: 0,
        },
        overdue: {
          value: 0,
          predicted: 0,
          secondValue: 0,
        },
        paidOff: {
          value: 0,
          secondValue: 0,
        },
        total: {
          value: 0,
          predicted: 0,
          secondValue: 0,
        },
      },
    }
  },

  computed: {
    ...mapState('pages/financial/receivables', [
      'filters',
      'paging',
      'sorting',
      'receivables',
      'selectedInstallments',
      'summaryFields',
    ]),

    hasBulkPayment() {
      return this.selectedInstallments.length > 0
    },

    countBulkPayment() {
      return this.selectedInstallments.length
    },

    getTotalInstallmentsSelected() {
      return this.selectedInstallments.reduce((total, inst) => total + inst.value, 0)
    },

    canDelete() {
      return row =>
        this.$can('Delete', 'ReceivableInstallment') &&
        !row.item.paymentDate &&
        !row.item.receivable?.sale?.id
    },

    canUpdate() {
      return item => {
        const isPartial = !!item.receivable.receivableOriginId

        return (
          !this.allActionsBlocked(item) &&
          (isPartial || item.receivable.recordType === 'Manual') &&
          !item.paymentDate
        )
      }
    },

    fields() {
      return [
        {
          label: this.$t('Pagamento em massa'),
          key: 'bulkPay',
          tdClass: 'text-center',
          thStyle: { minWidth: '50px', width: '50px' },
        },
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { minWidth: '100px', width: '100px' },
        },
        {
          label: this.$t('Emis.'),
          key: 'issueDate',
          sortKey: 'receivable.issueDate',
          tdClass: 'text-center',
          thStyle: { minWidth: '100px', width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Venc.'),
          key: 'dueDate',
          tdClass: 'text-center',
          thStyle: { minWidth: '100px', width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Loja'),
          key: 'store',
          sortKey: 'accountsPayable.storeId',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { minWidth: '60px', width: '60px' },
          sortable: true,
        },
        {
          label: this.$t('Cliente'),
          key: 'customer',
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { minWidth: '250px', width: '250px' },
        },
        {
          label: this.$t('Descrição'),
          key: 'description',
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { minWidth: '300px', width: '300px' },
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          tdClass: 'text-right',
          thStyle: { minWidth: '120px', width: '120px' },
          formatter: value => this.$options.filters.currency(value),
          sortable: true,
        },
        // {
        //   label: this.$t('Taxa'),
        //   key: 'commissionValue',
        //   tdClass: 'text-right',
        //   thStyle: { minWidth: '120px', width: '120px' },
        //   formatter: value => this.$options.filters.currency(value, '-'),
        //   sortable: true,
        // },
        {
          label: this.$t('Pago'),
          key: 'paidValue',
          tdClass: 'text-right',
          thStyle: { minWidth: '120px', width: '120px' },
          formatter: value => this.$options.filters.currency(value, '-'),
          sortable: true,
        },
        {
          label: this.$t('Forma de pagamento'),
          key: 'paymentMethod',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { minWidth: '100px', width: '100px' },
        },
        // {
        //   label: this.$t('Nº Doc'),
        //   key: 'document',
        //   tdClass: 'text-left',
        //   thStyle: { minWidth: '200px' },
        // },
        // {
        //   label: this.$t('Informações'),
        //   key: 'info',
        //   // thStyle: { 'min-width': '200px' },
        // },
        // {
        //   label: this.$t('Info. Adicionais'),
        //   key: 'aditionalInfo',
        //   thClass: 'text-center',
        //   tdClass: 'text-left',
        // },
      ]
    },

    totalValue() {
      return this.summaryFields?.value?.value
    },

    totalPaidValue() {
      return this.summaryFields?.paidValue?.value
    },

    totalCommissionValue() {
      return this.summaryFields?.commissionValue?.value
    },

    fabActions() {
      return [
        {
          name: 'clean-bulk-receivement',
          icon: 'backspace',
          color: 'red',
          tooltip: `${this.$t('Limpar recebimento em massa')} (${this.countBulkPayment})`,
          hide: !this.hasBulkPayment,
        },
        {
          name: 'confirm-bulk-receivement',
          icon: 'payments',
          color: 'green',
          tooltip: `${this.$t('Realizar recebimento em massa')} (${this.countBulkPayment})`,
          hide: !this.hasBulkPayment,
        },
        {
          name: 'add-new',
          icon: 'add',
          color: 'green',
          tooltip: this.$t('Adicionar recebimento'),
          hide: !this.$can('Create', 'AccountsPayable'),
        },
        {
          name: 'add-import',
          icon: 'note_add',
          color: 'green',
          tooltip: this.$t('Importar Receitas'),
          hide: !this.$can('BulkCreate', 'AccountsPayable'),
        }
      ].filter(act => !act.hide)
    },

    gridActions() {
      return row =>
        [
          {
            icon: 'cash',
            variant: 'outline-primary',
            title: this.$t('Realizar Baixa'),
            event: 'receive-account',
            hide: !!row.item.paymentDate,
          },
          {
            icon: 'x-circle',
            variant: 'danger',
            title: this.$t('Estornar Baixa'),
            event: 'reverse-payment',
            hide:
              !row.item.paymentDate ||
              // Não podemos deixar estornar recebíveis em dinheiro, pois eles já nascem baixados
              row.item.receivable.paymentMethod?.id === 1 ||
              !this.$can('Reverse', 'FinancialWriteOff'),
          },
        ].filter(btn => !btn.hide && !this.allActionsBlocked(row.item))
    },

    incomeTypes() {
      return [
        { value: 'balcony', label: this.$t('Balcão') },
        { value: 'app_site', label: this.$t('App/Site') },
        { value: 'budget', label: this.$t('Orçamento') },
        { value: 'others', label: this.$t('Outros') },
      ]
    },
    statusTypes() {
      return [
        { value: 'isPaid', label: this.$t('Pago') },
        { value: 'isPending', label: this.$t('Pendente') },
        { value: 'isLate', label: this.$t('Em atraso') },
      ]
    },
  },

  watch: {
    receivables() {
      this.verifyGridItensSelect()
    },
  },

  mounted() {
    this.getData()
    this.fetchCheckingAccounts()
  },

  methods: {
    ...mapActions('pages/financial/receivables', [
      'fetchReceivables',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
      'addInstallmentsToPay',
      'reversePayment',
      'removeInstallmentsToPay',
      'cleanInstallmentsToReceive',
    ]),
    ...mapActions('app', ['fetchCheckingAccounts']),
    ...mapActions('common/clients', {
      stSearchClients: 'searchClients',
    }),
    ...mapActions('pages/financial/receivables/receivablesBulkMaintain', {
      cleanBulkMaintain: 'cleanState',
    }),

    allActionsBlocked(item) {
      // pagamentos que tem origem uma transferência bancária, devem ser estornados na tela de transferências
      return !!item.receivable?.checkingAccountTransferId
    },

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchReceivables()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },

    filterData() {
      this.getData()
    },

    showCreateSidebar() {
      this.$refs.receivable_sidebar.show()
    },

    async showUpdateSidebar(row) {
      this.$refs.receivable_sidebar.show({ id: row.item.receivable.id })
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    verifyGridItensSelect() {
      const hasUnselectedRow = this.receivables.some(
        row => !this.selectedInstallments.some(selected => row.id === selected.id)
      )
      this.allCheckedToReceive = !hasUnselectedRow
    },

    onShowDetails(row) {
      this.$refs.receivableInstallmentInfoSidebar.show(row.item)
    },

    showReceiveModal(row) {
      const { item } = row
      this.$refs.receiveInstallmentModal.showModal(false, [item.id])
    },
    showBulkPayModal() {
      const installmentIdList = this.selectedInstallments.map(i => i.id)
      this.$refs.receiveInstallmentModal.showModal(true, installmentIdList)
    },
    async onAfterPay(itemList) {
      await Promise.all(
        itemList.map(async item => {
          await this.removeInstallmentsToPay(item)
        })
      )
      await this.getData()
    },

    async cleanBulkPayemnt() {
      const confirmed = await this.confirm({
        text: this.$t('Os recebimentos selecionados serão desmarcados'),
      })
      if (!confirmed) return

      this.cleanInstallmentsToReceive()
      this.getData()
    },

    createBulkBill() {
      this.cleanBulkMaintain()
      this.$router.push({ name: 'receivable-bulk-add' })
    },

    async onRowClickToSelectPay(item) {
      if (item.paymentDate === null) {
        const checkValue = !item.checkedToReceive
        // eslint-disable-next-line no-param-reassign
        item.checkedToReceive = checkValue
        this.onSelectToPay(checkValue, item)
      }
    },

    onSearchReceivableOrigin(receivableOriginId) {
      this.filters.receivableOriginId = receivableOriginId
      this.getData()
    },

    async onSelectAllToPay(value) {
      let errorMessage = ''
      this.receivables
        .filter(item => item.paymentDate === null)
        .forEach(item => {
          try {
            // eslint-disable-next-line no-param-reassign
            item.checkedToReceive = value
            if (value) {
              this.addInstallmentsToPay(item)
            } else {
              this.removeInstallmentsToPay(item)
            }
          } catch (e) {
            errorMessage = e.message
            // eslint-disable-next-line no-param-reassign
            item.checkedToReceive = !value
          }
        })
      if (errorMessage) {
        this.showWarning({ message: errorMessage })
      }
    },

    async onSelectToPay(value, item) {
      try {
        if (value) {
          await this.addInstallmentsToPay(item)
        } else {
          await this.removeInstallmentsToPay(item)
        }
      } catch (error) {
        this.showWarning({ message: error.message })
        // eslint-disable-next-line no-param-reassign
        item.checkedToReceive = !value
      }
    },
    async localReversePayment(installment) {
      const confirmed = await this.confirm({
        text: this.$t('A baixa será cancelada e o lançamento na conta corrente será excluído.'),
      })
      if (!confirmed) return

      try {
        this.fetching = true
        await this.reversePayment(installment.financialWriteOffId)
        this.showSuccess({ message: this.$t('Baixa estornada com sucesso') })
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
    async onDeleteInstallment(installment) {
      const confirm = await this.confirm({
        text: `Tem certeza que deseja excluir a receita de ${this.$options.filters.currency(
          installment.value
        )} do cliente ${installment.receivable?.customer?.name}? Não há como desfazer esta ação.`,
      })
      if (!confirm) return

      try {
        this.fetching = true
        await this.$http.delete(`/api/accounting/receivables-installments/${installment.id}`)
        this.showSuccess({ message: this.$t('Parcela excluída com sucesso') })
        await this.getData()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    date(value) {
      return this.$options.filters.date(value)
    },

    rowClass(item, type) {
      let clazz = item?.checkedToReceive ? 'selected-row' : ''
      if (item && type === 'row') {
        if (!item.paymentDate) {
          clazz += ' payment-pending'
        }
      }
      return clazz
    },

    showModifiedBy(item) {
      return (
        item.modifiedDate &&
        (Math.abs(moment(item.modifiedDate).diff(item.writtenOffDate, 'seconds')) > 5 ||
          !item.writtenOffDate)
      )
    },
  },
}
</script>

<style lang="scss">
.receivable-select-row {
  & table tbody .payment-pending td {
    cursor: pointer !important;
  }
}

.check-td {
  padding-left: 14px !important;
}
</style>
